#weighingMachineProduct {
    .container {
        position: relative;
        height: 250px;
        text-align: center;
        border: 3px solid #999;
        border-radius: 8px;
        margin: 8px;
        overflow: hidden;
        box-shadow: 2px 2px 5px 0 #0005;
        transition: border 0.5s ease-in-out;
        &:hover {
            border-color: var(--secondary);
        }
        .productImage {
            width: 100%;
            height: 150px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .name {
        }
        .price {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
        }
    }
}
