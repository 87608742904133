.botao-voltar-component {
  position: fixed !important;
  color: var(--on-primary) !important;
  background-color: #0005 !important;
  text-shadow: 2px 2px 6px #000;
  left: 8px !important;
  top: 8px !important;
  font-size: 32px !important;
  z-index: 10;
  /* bottom: 0; */
}
