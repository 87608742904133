.selectCardType {
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    border-radius: 8px;
    border-radius: 8px;
    overflow: hidden;
    /* background-color: aqua; */
    padding: 16px;
}

.total-selectCardType {
    position: relative;
    bottom: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: #0009 solid 2px;
    padding: 16px;
    font-size: 50px;
    color: #444;
    text-align: center;
    margin-top: 16px;
}

.text-selectCardType {
    font-size: 32px;
    text-align: center;
    color: #333;
}

.buttonsContainer-selectCardType {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* background-color: aquamarine; */
}

.button-selectCardType {
    border: solid #aaa 2px;
    font-size: 32px;
    margin: 16px;
    color: #555;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 3px 3px 5px 0px #0005;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-selectCardType:active {
    border: solid  var(--primary) 2px;
    box-shadow: 3px 3px 10px 0px #0005;
    transition: all 0.3s ease-in-out;
}