body {
    margin: 0;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    font-size: 22px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Rainbow";
    src: url("./assets/fonts/Rainbow/Rainbow Paper.ttf") format("truetype");
    font-weight: bold;
}

::-webkit-scrollbar {
    width: 0px;
    height: 5px;
}


::-webkit-scrollbar-track {
    background: #00000002;
}


::-webkit-scrollbar-thumb {
    background: #00000002;
    border-radius: 20px;
}


::-webkit-scrollbar-thumb:hover {
    background: #00000002;
    opacity: 0.5;
}

.botaoContained {
    padding: 12px;
    flex: 1;
    background-color: var(--primary);
    color: var(--on-primary);
    font-size: xx-large;
    flex: 1;
    border-radius: 4px;
}

.botaoContained:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.02);
}

.botaoOutlined {
    padding: 12px;
    flex: 1;
    color: var(--primary);
    border-radius: 4px;
    border: 2px solid var(--primary);
    background-color: transparent;
    font-size: xx-large;
}

.botaoOutlined:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.02);
}

.botaoOutlined:active {
}

h4 {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
}
h3 {
    font-size: 26px;
    font-weight: 400;
    margin: 0;
}
h2 {
    font-size: 32px;
    font-weight: 400;
    margin: 0;
}

h1 {
    font-size: 44px;
    font-weight: 400;
    margin: 0;
}
