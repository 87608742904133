.message-reading-toast {
    display: flex;
}
.reading-toast {
    position: fixed;
    background-color: #0005;
    top: 0;
    left: 0;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    color: white;
}

.message-reading-toast {
    width: 980px;
    height: 900px;
    padding: 32px 0;
    border-radius: 16px;
    animation: in-open 1s;
    background: #955CFF;
    display: flex;
    flex-direction: column;
    align-itens: center;
    justify-content: space-between;
}

.message-reading-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.message-reading-timer >div {
    display: flex;
    align-items: center;
    text-align: center;
}

.message-reading-timer span {
    color: #fff;
    font-weight: bold;
}

.message-reading-timer >span {
    margin: 0 0.5rem;
}

.message-reading-toast >h1 {
    font-size: 60px;
    font-weight: bold;
}

.toast-wait-time {

}

.toast-wait-time >div{
    margin: 32px 175px;
}

.toast-wait-time >div>div>div{
    background-color: #955CFF;
}

.toast-wait-time >p {
    margin: 16px;
    font-size: 50px;
}

.text-reading-toast {
    /* white-space: pre-line;
    border-top: 2px solid var(--neutral-white, #FFF);
    border-bottom: 2px solid var(--neutral-white, #FFF); */
}

.text-reading-toast >p{
    margin: 16px 0;
    font-size: 50px;    
}

.message-reading-warning >h1 {
    font-size: 50px;
    font-weight: bold;
}


.reading-toast-totem {
    mix-blend-mode: screen;
    width: 550px;
    height: 350px;
    object-fit: cover;
    align-self: center;
}