.StorePage {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ModalDetalhamento{
  position: relative;
  display: flex;
  flex:1;
  width: 100vw;
  height: 100vh;
}
.pesarOutroPratoButton{
  display: flex;
  background: rgb(255,0,67);
background: linear-gradient(90deg, rgba(255,0,67,1) 0%, rgba(109,0,255,1) 100%);
  font-size: 32px;
  color: #fff;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-radius: 16px;
  margin: 16px;
}