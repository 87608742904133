#invoiceLayoutPrinter {
    background-color: #ffffff;
    width: 400px;
    visibility: visible;
    overflow: visible;
    position: relative;
    padding: 24px;
}

#invoiceLayoutPrinter .title {
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    margin-bottom: 8px;
}

#invoiceLayoutPrinter .sub-title {
    font-size: 26px;
    text-align: center;
    padding-bottom: 8px;
}

#invoiceLayoutPrinter .invoice-divisor {
    width: 100%;
    border-bottom: 2px dashed black;
    margin: 8px 0;
}

#invoiceLayoutPrinter .info {
    font-size: 18px;
}

#invoiceLayoutPrinter .info-b {
    font-size: 16px;
}

#invoiceLayoutPrinter .products {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
}

#invoiceLayoutPrinter .products > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    margin-top: 8px;
}

#invoiceLayoutPrinter .products > div:last-of-type {
    justify-content: flex-end;
}

#invoiceLayoutPrinter .item {
    font-size: 18px;
    margin-top: 8px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

#invoiceLayoutPrinter .item-b {
    font-size: 22px;
    margin: 8px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

#invoiceLayoutPrinter .item-c {
    font-size: 16px;
    margin: 8px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

#invoiceLayoutPrinter .qrCode {
    padding: 8px;
    margin-top: 8px;
}