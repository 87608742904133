.produto-destaque {
  position: relative;
  /* flex: 1; */
  box-shadow: 0px 10px 10px -5px #0003 !important;
  /* height: 230px; */
  background-color: var(--background);
  border-radius: 8px;
  margin-left: 32px;
  min-height: 200px;
  min-width: 350px;
  padding-bottom: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.produto-destaque-click-div{
  position: absolute;
  top:0;
  right: 0;
  left: 0;
  height: 180px;
}

.produto-destaque-desconto{
  position: absolute;
  top: 10px;
  right: 0;

  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  background-color: red;
  z-index: 999;
  font-weight: bold;
}
.produto-destaque-desconto:before,
.produto-destaque-desconto:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background: red;
  z-index: -1;
}
.produto-destaque-desconto:before {
  transform: rotate(30deg);
}
.produto-destaque-desconto:after {
  transform: rotate(60deg);
}

.produto-destaque .image {
  background-color: #666;
  width: 200px;
  height: 200px;
  border-radius: 8px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.produto-destaque .info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000a;
  border-radius: 4px;
  padding: 4px;
}
.produto-destaque .name {
  color: var(--primary);
  color: #fff;
  font-weight: 600;
}
.produto-destaque .description {
  font-size: 12px;
  opacity: 0.5;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.produto-destaque .value {
  /* position: absolute; */
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}
.produto-destaque .botoes {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -15px;
  right: -15px;
}
.produto-destaque .add-botao {
  background-color: #1ca !important;
  color: #fff !important;
}
.produto-destaque .remove-botao {
  background-color: #ff7777 !important;
  margin-right: 4px !important;
  max-width: 50px !important;
  max-height: 50px !important;
  color: #fff !important;
}

.produto-destaque .remove-botao-visivel {
  transform: translateX(0px);
  transition: transform 0.5s ease-in-out;
}
.produto-destaque .remove-botao-oculto {
  transform: translateX(55px);
  transition: transform 0.5s ease-in-out;
}

.produto-destaque .quantidade-oculto {
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}
.produto-destaque .quantidade-visivel {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.produto-destaque .quantidade {
  position: absolute;
  top: -15px;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff !important;
}
