.FullScreenToast {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0da;
    color: #fff;
    font-size: 32px;
    /* z-index: 3; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}

.message-FullScreenToast {
    animation: in-open 1s;
    /* animation-delay: 0.4s; */
}

.icon-FullScreenToast {
    margin: auto;
    font-size: 28px;
    display: flex;
    justify-content: center;
}

.text-FullScreenToast {
    white-space: pre-line
}

.modal-sucess-enter {
    transform: translateY(100vh);
}

.modal-sucess-enter-active {
    transform: translateY(0vh);
    transition: transform 400ms;
}

.modal-sucess-exit {
    transform: translateY(0vh);
}

.modal-sucess-exit-active {
    transform: translateY(100vh);
    transition: transform 400ms;
}

.button-FullScreenToast{
    font-size: xx-large;
    border-radius: #fff;
    color: #fff;
}

@keyframes in-open {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}