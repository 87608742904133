.produto-lista {
    position: relative;
    padding: 16px 0;
    padding-bottom: 32px;
    width: 100vw;
    overflow: hidden;
}

.produto-lista .lista-horizontal {
    display: flex;
    overflow: scroll;
    padding-bottom: 32px;
}

.produto-lista .lista-vertical {
    overflow-x: visible;
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-template-rows: 320px;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
}

.listaFade-appear,
.listaFade-enter {
    opacity: 0;
    transform: scale(0);
}

.listaFade-appear-active,
.listaFade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: transform 300ms, opacity 300ms;
    /* transition: opacity 500ms ; */
}

.listaFade-exit {
    opacity: 1;
    transform: scale(1);
}

.listaFade-exit-active {
    opacity: 0;
    transform: scale(0);
    transition: opacity 300ms, transform 400ms;
}

.listaFade {
    animation: moveAcross 0.5s ease-in-out;
}

@keyframes moveAcross {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 100%;
        transform: scale(1);
    }
}