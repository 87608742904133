#KeyboarInstalment{
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 95vw;
    max-width: 600px;
    font-size: 30px;
    text-align: center;
    .botao{
        font-size: large;
        background-color: #1ac;
        color: #fff;
        font-size: 40px;
        margin-bottom: 16px;
        height: 80px;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        display: flex;

    }

    .botaoCancelar{
        @extend .botao;
        background-color: #c01;
    }

}