.content-ProdutoPedidoPosPreview {
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  font-size: large;
}
.nome-ProdutoPedidoPosPreview {
}
.quantidade-ProdutoPedidoPosPreview {
    text-align: center;
}
.total-ProdutoPedidoPosPreview {
}
.actions-ProdutoPedidoPosPreview {
}
