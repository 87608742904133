.transition-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary);
  z-index: 6;
  animation: in-anim-transition-page 5s;
}

.transition-logo {
  background-image: url(../../../assets/images/m-white.png);
  width: 400px;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
}

@keyframes in-anim-transition-page {
  0% {
    transform: translateY(100vh);
  }
  5% {
    transform: translateY(0vh);
  }
  25% {
    transform: translateY(0vh);
  }
  30% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(-100vh);
  }
}
