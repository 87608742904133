#keyboardPimpad {
    display: flex;
    width: 100vw;
    height: 100vh;
    /* background-color: aqua; */
    justify-content: center;
    align-items: center;
}
.keyboardFade {
    animation: keyboarAnimationFade;
    animation-duration: 2ms;
}

@keyframes keyboarAnimationFade {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
       opacity: 100;
    }
}
