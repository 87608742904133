#ModalRetirada {
    font-size: 32px;
    text-align: center;
    .header {
        background-color: var(--primary);
        display: flex;
        color: #fff;
        height: 80px;
        align-items: center;
        justify-content: center;
        font-size: 32px;
    }
    .container {
        padding: 32px;
        .button {
            border: solid #aaa 2px;
            font-size: 32px;
            margin: 16px;
            color: #555;
            padding: 16px;
            border-radius: 16px;
            box-shadow: 3px 3px 5px 0px #0005;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:active {
                border: solid var(--primary) 2px;
                box-shadow: 3px 3px 10px 0px #0005;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}
