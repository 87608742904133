.produto-detalhamento-simple {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  overflow: scroll;
  align-items: center;
  background-color: var(--background);
  border-radius: 16px;
}
.produto-detalhamento-simple .image-detalhamento-simple {
  position: absolute;
  background-color: #666;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
  /* visibility: hidden; */
}
.observation {
  padding: 16px 0 100px;
}

.observation-label {
  padding-top: 16px;
}
.produto-detalhamento-simple .conteudo-detalhamento-simple {
  position: absolute;
  bottom: 90px;
  /* width: 100vw; */
  max-height: 400px;
  overflow: scroll;
  z-index: 1;
  background-color: #0008;
  text-shadow: 2px 2px 2px #000;
  left: 0;
  right: 0;
}

.produto-detalhamento-desconto{
  position: absolute;
  top: 20px;
  right: 20px;

  width: 100px;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 36px;
  font-weight: bold;
  color: white;

  background-color: red;
  z-index: 9999;
}
.produto-detalhamento-desconto:before,
.produto-detalhamento-desconto:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  width: 100px;
  background: red;
  z-index: -1;
}
.produto-detalhamento-desconto:before {
  transform: rotate(30deg);
}
.produto-detalhamento-desconto:after {
  transform: rotate(60deg);
}

.produto-detalhamento-simple .titulo-detalhamento-simple {
  font-size: 28px;
  color: #fff;
  text-align: center;
}
.produto-detalhamento-simple .descricao-detalhamento-simple {
  font-size: 18px;
  opacity: 0.9;
}
.descricao-detalhamento-simple {
  color: #FFF;
  padding: 8px;
}
.rodape-detalhamento-simple {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  /* height: 70px; */
  left: 0;
  right: 0;
  /* border-top: 2px solid #ddd; */
  /* background-color: aqua; */
  background-color: var(--background);
  padding: 16px;
  z-index: 1;
  animation: in-rodape 0.3s;
}
.container-quantidade-detalhamento-simple {
  display: flex;
  margin-right: 32px;
}
.add-quantidade-detalhamento-simple {
  /* background-color: #1ca !important; */
  background-color: #fff !important;
  border: 2px solid #1ca !important;
  color: #1ca !important;

  min-height: 60px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.quantidade-detalhamento-simple {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  border-top: 2px solid #0002;
  border-bottom: 2px solid #0002;
}
.remove-quantidade-detalhamento-simple {
  background-color: #fff !important;
  border: 2px solid #f77 !important;
  color: #f77 !important;
  min-height: 60px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.botao-continuar-detalhamento-simple {
  background-color: var(--primary) !important;
  /* border: 2px solid #f77 !important; */
  color: #fff !important;
  padding: 0 32px !important;
  font-size: 26px !important;
  height: 60px !important;
}

@keyframes in-rodape {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
