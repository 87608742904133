.container-order-log-item {
  border-bottom: 1px #666 solid;
  /* background-color: #666; */
  height: 50px;
  font-size: medium;
}
.content-order-log-item {
  align-items: center;
}
.horario-order-log-item {
  text-align: left;
}

.quantidade-order-log-item {
}
.total-order-log-item {
}
