#remoteLayoutPrinter {
  background-color: #ffffff;
  visibility: visible;
  overflow: visible;
  font-size: larger;
  position: relative;
  max-width: 300px;
  font-size: 18px;
}

#remoteLayoutPrinter .localNameRemote {
  font-weight: bold;
  margin-bottom: 8px;
}
#remoteLayoutPrinter .observation-RemoteLayoutPrintern {
  margin-bottom: 8px;
}

.text-ordersItem-RemoteLayoutPrinter {
  display: flex;
  justify-content: space-between;
  font-size: large;
}

.composition-ordersItem-RemoteLayoutPrinter {
  font-size: medium;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  text-align: left;
}

#remoteLayoutPrinter .content-OrderItem {
  padding: 4px;
  border: 1px solid #000;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 8px;
}
#remoteLayoutPrinter .container-RemoteOrderItem {
  margin-bottom: 8px;
}

.descriptionOptionConsumer-ordersItem-RemoteLayoutPrinter {
  display: flex;
}

.optionsItemsOptionConsumer-ordersItem-RemoteLayoutPrinter {
  margin-left: 16px;
}

.optionItemOptionConsumer-ordersItem-RemoteLayoutPrinter {
  display: flex;
  justify-content: space-between;
}

.amount-RemoteLayoutPrinter {
  font-size: x-large;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title-RemoteLayoutPrinter {
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;  
}

.date-RemoteLayoutPrinter {
  font-size: 16px;
  text-align: start;
}

.remote-layout-dotted-separator {
  margin: 16px 0;
  text-align: start;
}

#remoteLayoutPrinter .textLabelPedido {
  font-size: 16px;
}

#remoteLayoutPrinter .textPedido {
  font-weight: bold;
}

#remoteLayoutPrinter .observation {
}
#remoteLayoutPrinter .kioskName {
}

#remoteLayoutPrinter .productCategory {
  font-weight: 550;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between; 
}

#remoteLayoutPrinter .productPrice {
  font-size: x-large;
}

#remoteLayoutPrinter .consumerOptions {
  padding: 16px;
  text-align: left;
}
.productPassWordOrderRemote {
  border: 2px solid #000;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #000;
  padding: 8px 0;
  margin: 8px 0;    
}

.name-remote-product {
  font-weight: bold;
}

.name-ordersItem-RemoteLayoutPrinter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name-remote-product-price {
  font-weight: bold;
}

#remoteLayoutPrinter .consumerOptions .descricao {
  font-weight: 550;
}

#remoteLayoutPrinter .consumerOptions .option {
  display: flex;
  justify-content: space-between;
  margin-left: 4px;
}

