.inputCpf {
    color: #666;
    /* background-color: aqua; */
    padding: 16px;
}

.description-inputCpf {
    text-align: center;
}

.teclado-inputCpf {}

.value-inputCpf {
    height: 50px;
    text-align: center;
    /* border: #222 solid 2px; */
    box-shadow: #0003 2px 2px 10px -5px;
    margin-bottom: 16px;
    border-radius: 4px;
}

.buttons-inputCpf {
    position: relative;
    display: flex;
    flex-direction: row;
    /* background-color: bisque; */
    margin-top: 16px;
}

.button-inputCpf {
    border-radius: 8px !important;
}

.continuar-inputCpf {
    display: flex !important;
    flex: 1 !important;
    background-color: var(--primary) !important;
    color: #fff !important;
    margin-left: 8px !important;
    font-size: 24px !important;
}

.nao-informar-inputCpf {
    line-height: 22px !important;
    font-size: 18px !important;
    display: flex !important;
    flex: 0.5 !important;
    color: var(--primary) !important;
    border: var(--primary) 2px solid !important;
    transition: opacity 0.3s !important;
}

.disabled-continuar-inputCpf {
    opacity: 0.3;
    transition: opacity 0.3s !important;
}

.enable-continuar-inputCpf {
    opacity: 1;
    transition: opacity 0.3s !important;
}