#WalletReceiptLayout {
    background-color: #ffffff;
    font-weight: 500;
    visibility: visible;
    overflow: visible;
    font-size: larger;
    border-top: 5px double #ddd;
    /* border-bottom: 5px double #000; */
    position: relative;
    padding: 32px;
    font-size: medium;
    max-width: 300px;
}


#WalletReceiptLayout .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-bottom: 1px solid black;
}

#WalletReceiptLayout .header > span {
    font-size: 14px;
}

#WalletReceiptLayout .header > h5 {
    margin: 0;
    font-size: 18px;
}

#WalletReceiptLayout .header > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#WalletReceiptLayout .header > div > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

#WalletReceiptLayout .header > div > div > p {
    margin: 0;
    font-size: 14px;
}

#WalletReceiptLayout .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}

#WalletReceiptLayout .content > p {
    margin: 0;
    font-size: 14px;
}

#WalletReceiptLayout .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


