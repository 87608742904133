.composicao {
  margin: 16px 16px;
  padding: 8px;
  border: solid 1px #ddd;
  background-color: #fff;
  border-radius: 8px;
}

.composicao .composicao-descricao {
  font-size: 24px;
}

.composicao-grid-conainer {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}
