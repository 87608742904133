.teclaContainer {
  display: flex;
  flex: 1;
  height: 75px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 6px 0px #0004;
  overflow: hidden;
  color: #666;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #000 !important; */
  transition: all 0.2s;
  &:active {
    opacity: 0.5;
    box-shadow: 2px 2px 10px 0px #0004;
    transform: scale(1.2);
  }
}
