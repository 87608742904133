#RequestDocument {
    display: flex;
    // background-color: aqua;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    // width: 600px;

}

.reading {
    >div>div {
        align-items: center;
    }
}