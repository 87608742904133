.carrinho-item {
  display: flex;
  flex: 1;
  /* height: 100px; */
  overflow: hidden;
  border-radius: 16px;
  /* box-shadow: 0px 2px 10px -5px #0003; */
  border: 1px solid #eee;
  margin-bottom: 0;
  padding: 8px;
  min-height: 100px;
  /* background-color: #666; */
  /* margin: 32px 0; */
  /* padding: 8px; */
}

.carrinho-item .image {
  width: 100px;
  height: 100px;

  border-radius: 8px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.additional-carrinhoItem {
  word-break: break-all;
}
.carrinho-item .info {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
  padding: 8px;
}
.carrinho-item .nome {
  font-size: 18px;
  /* font-weight: 600; */
}
.carrinho-item .descricao {
  opacity: 0.5;
}
.carrinho-item .valor {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 130px;
  padding: 16px;
  font-size: 26px;
  /* background-color: #ff7777; */
  color: #1a6 !important;
}

.carrinho-item .quantidadeContainer {
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
}

.carrinho-item .quantidade {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-size: 22px;
  margin: 8px;
  background-color: #0001;

  width: 40px !important;
  height: 40px !important;
}

.carrinho-item .remove-weight {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 25px; */
  font-size: 22px;
  margin: 8px;
  /* background-color: #0001; */

  color: #ff7777;

  width: 40px !important;
  height: 40px !important;
}

.carrinho-item .quantidadeGranelContainer {
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
}

.carrinho-item .quantidadeGranel {
  font-size: medium;
}

.carrinho-item .add-botao {
  background-color: #1ca !important;
  color: #fff !important;
  max-width: 45px !important;
  max-height: 45px !important;
}
.carrinho-item .remove-botao {
  background-color: #ff7777 !important;
  margin-right: 4px !important;
  max-width: 45px !important;
  max-height: 45px !important;
  color: #fff !important;
}
.carrinho-item .cart-item-botao {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 16px;
}

/* .carrinho-item .remove-botao-visivel {
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}
.carrinho-item .remove-botao-oculto {
  transform: scale(0);
  transition: transform 0.5s ease-in-out;
} */
