.toolbar {
  position: sticky;
  width: 100%;
  height: 200px;
  top: -125px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  z-index: 9999;
  /* filter: blur(5px); */
}
.toolbar .botao-voltar {
  color: var(--on-primary);
  position: fixed;
  left: 16px;
  top: 16px;
  z-index: 10;
  /* bottom: 0; */
}
.toolbar .closed {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  top: 16px;
  right: 16px;
  background-color: #DE9C00;
}
.name-toolbar {
  color: #fff;
  text-shadow: 2px 2px 6px #000d;
  font-size: 36px;
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.student-toolbar {
  position: fixed;
  left: 8px;
  right: 8px;
  top: 0px;
  
  display: flex;
  
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 0px 0px 16px 16px;
  background: rgba(255, 255, 255, 0.80);
}

.student-toolbar > div {
  display: flex;
  align-items: center;
  color: var(--Neutral-Gray-900, #333);
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  text-transform: capitalize;
}

.student-toolbar > div:first-of-type > b {
  max-width: 155px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student-toolbar > div > span {
  margin-left: 4px;
}