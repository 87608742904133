.produto-item {
  position: relative;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  box-shadow: 0px 10px 10px -5px #0003 !important;
  /* height: 230px; */
  background-color: var(--background);
  border-radius: 8px;
  margin-left: 16px;
  height: 320px;
  max-width: 200px;
  /* padding-bottom: 32px; */
  background-blend-mode: color;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.produto-item .image {
  background-color: #ddd;
  width: 200px;
  height: 200px;
  border-radius: 8px;
  /* animation: imageOpacity 0.5s; */
  object-fit: cover;
  background-position: center;

  /* visibility: hidden; */
}

.produto-item-desconto-valor {
  text-decoration: line-through;
  font-size: 16px;
  color: lightgray;
  transform: rotate(-30deg);
  transform-origin: left center;
}

.produto-item-desconto{
  position: absolute;
  top: 10px;
  right: 0;

  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  background-color: red;
  z-index: 999;
  font-weight: bold;
}
.produto-item-desconto:before,
.produto-item-desconto:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 50px;
  background: red;
  z-index: -1;
}
.produto-item-desconto:before {
  transform: rotate(30deg);
}
.produto-item-desconto:after {
  transform: rotate(60deg);
}

.produto-item .info {
  bottom: 0;
  background-color: #fffd;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.produto-item .name {
  color: var(--primary);
  font-weight: 500;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: left;


  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.produto-item .description {
  font-size: 13px;
  opacity: 0.5;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.produto-item .value {
  display: flex;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.produto-item .botoes {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -15px;
  right: -15px;
}
.produto-item .add-botao {
  background-color: #1ca !important;
  color: #fff !important;
}
.produto-item .remove-botao {
  background-color: #ff7777 !important;
  margin-right: 4px !important;
  max-width: 50px !important;
  max-height: 50px !important;
  color: #fff !important;
}

.produto-item .remove-botao-visivel {
  transform: translateX(0px);
  transition: transform 0.5s;
}
.produto-item .remove-botao-oculto {
  transform: translateX(55px);
  transition: transform 0.5s;
}

.produto-item .quantidade-oculto {
  transform: scale(0);
  transition: transform 0.3s;
}
.produto-item .quantidade-visivel {
  transform: scale(1);
  transition: transform 0.3s;
}
.produto-item .quantidade {
  position: absolute;
  top: -15px;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff !important;
}

@keyframes imageOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
