.composicao-item {
  display: flex;
  flex: 1;
  height: 70px;
  align-items: center;
  border-radius: 8px;
  /* box-shadow: 0px 10px 10px -5px #0003 !important; */
  margin: 8px 0;
  padding: 8px;
  border: 2px solid #eee;
  background-color: #fff;
}

.composicao-item-selected {
  box-shadow: 0px 10px 10px 0px #0003 !important;
  border: 2px solid #1ca;
}

.composicao-item .composicao-item-image {
  width: 70px;
  height: 70px;

  border-radius: 8px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.composicao-item .composicao-item-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
  padding: 16px;
}
.composicao-item .composicao-item-nome {
  font-size: 18px;
  /* font-weight: 600; */
}
.composicao-item .composicao-item-descricao {
  font-size: 18px;
  /* font-weight: 600; */
}
.composicao-item .composicao-item-valor {
}

.container-composicao-item {
  display: flex;
  justify-content: flex-end;
}

.composicao-item .composicao-item-valor-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  font-size: 26px;
  color: #1a6 !important;
}

.composicao-item .composicao-item-quantidadeContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.composicao-item .composicao-item-quantidade {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-size: 22px;
  margin: 8px;
  background-color: #0001;

  width: 40px !important;
  height: 40px !important;
}

.composicao-item .composicao-item-add-botao {
  /* background-color: #fff !important;
  color: #1ca !important;
  border: #1ca solid 2px !important; */
  background-color: #1ca !important;
  color: #fff !important;


  max-width: 50px !important;
  max-height: 50px !important;
  transition: opacity 0.5s;
}
.composicao-item .composicao-item-remove-botao {
  max-height: 50px !important;
  /* color: #ff7777 !important;
  border: #ff7777 solid 2px !important;
  background-color: #fff !important; */
  color: #fff !important;
  background-color: #f77 !important;
  margin-right: 4px !important;
  max-width: 50px !important;
  transition: opacity 0.5s;
}

.composicao-item .composicao-item-botao-disabled {
  opacity: 0.2;
  transition: opacity 0.3s;
} 