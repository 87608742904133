.container-authKeyboard {
  min-width: 400px;

}

.instrucao-authKeyboard {
  font-size: 28px;
  text-align: center;
  opacity: 0.8;
}
.value-authKeyboard {
  flex: 1;
  align-items: center;
  font-size: 38px;
  text-align: center;
  /* background-color: aqua; */
}

.input-authKeyboard {
  min-width: 400px;
  display: flex;
  height: 50px;
  flex-direction: row;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 4px;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
}


.icon-authKeyboard {
  /* background-color: aqua; */
  font-size: 50px !important;
  color: #f66 !important;
  
}
.erro-authKeyboard {
  color: #f00;
  text-align: center;
  font-size: 18px;
  height: 26px;
}



.back-authKeyboard {
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  /* background-color: aqua; */
}