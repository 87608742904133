.payment {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: var(--background); */
    background-color: #0005;
    outline: none;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: in-mount-modal 0.5s;
    text-align: center;
    padding: 18px;
}

.paymentMessage {}

.contentPayment {
    position: relative;
    width: 90vw;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    border-radius: 8px;
    box-shadow: 3px 3px 20px -3px #0005;
    border-radius: 8px;
    overflow: hidden;
}

.topo-contentPayment {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    justify-content: center;
    text-align: center;
    padding: 16px;
    background-color: var(--primary);
    color: #fff;
}

.changeEtapa-contentPayment-enter {
    opacity: 0;
}

.changeEtapa-contentPayment-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.changeEtapa-contentPayment-exit {
    opacity: 1;
}

.changeEtapa-contentPayment-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.cartao_cashless {
    padding: 32px;
}

@keyframes in-mount-modal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}