.container-orderLogItemPreview {
  padding: 16px;
  min-width: 500px;
}
.content-orderLogItemPreview {
}
.horario-orderLogItemPreview {
}
.quantidade-orderLogItemPreview {
}
.total-orderLogItemPreview {
}
.total-orderLogItemPreview-nf {
  text-decoration: underline;
  font-size: 14px;
  padding-top: 8px;
}
.orders-orderLogItemPreview {
  box-shadow: 1px 1px 4px -1px #0006;
  border-radius: 4px;
  padding: 8px;
}
.orders-orderLogItemPreviewList {
  font-size: 12px;
}