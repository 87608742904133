#WalletRechargeLayout {
  background-color: #ffffff;
  font-weight: 500;
  visibility: visible;
  overflow: visible;
  font-size: larger;
  border-top: 5px double #ddd;
  position: relative;
  padding: 32px;
  font-size: medium;
  max-width: 300px;
}

#WalletRechargeLayout .header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#WalletRechargeLayout .header > span {
  font-size: 18px;
}

#WalletRechargeLayout .header > h5 {
  margin: 0;
  font-size: 30px;
}

#WalletRechargeLayout .header > div {
  width: 100%;
  display: flex;
  margin: 8px 0;
  align-items: center;
  justify-content: space-between;
}

#WalletRechargeLayout .header > div:first-of-type {
  padding: 8px 0;
}

#WalletRechargeLayout .header > div:last-of-type {
  padding: 8px 0;
  font-size: 22px;
}

#WalletRechargeLayout .header > div > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#WalletRechargeLayout .header > div > div > p {
  margin: 2px 0;
  font-size: 18px;
}

#WalletRechargeLayout .content {
  margin: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#WalletRechargeLayout .content > p {
  margin: 2px 0;
  font-size: 18px;
}

#WalletRechargeLayout .divisor {
  border-top: 1px solid black;
  width: 100%;
  margin: 8px 0;
}
