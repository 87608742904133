.container-passKeyboard {
  min-width: 400px;

}

.instrucao-passKeyboard {
  font-size: 28px;
  text-align: center;
  opacity: 0.8;
}
.value-passKeyboard {
  flex: 1;
  align-items: center;
  font-size: 64px;
  text-align: center;
  /* background-color: aqua; */
}

.input-passKeyboard {
  min-width: 400px;
  display: flex;
  height: 50px;
  flex-direction: row;
  /* border: 1px solid #ccc; */
  color: #000;
  /* border-radius: 4px; */
  margin: 8px 0;
  justify-content: center;
  align-items: center;
}


.icon-passKeyboard {
  /* background-color: aqua; */
  font-size: 50px !important;
  color: #f66 !important;
  
}
.erro-passKeyboard {
  color: #f00;
  text-align: center;
  font-size: 18px;
  height: 26px;
}



.back-passKeyboard {
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  /* background-color: aqua; */
}