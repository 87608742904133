#customerIdentificationContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .inputContainer {
        width: 80%;
        outline: none;
        border-radius: 8px;
    }

    .nfceContainer {
        background-color: white;
    }
}