#Extract {
  display: flex;
  flex-direction: column;

  max-height: 70%;
  width: 100%;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      margin: 0;
      color: var(--Neutral-Gray-700, #a2a2a2);
      text-align: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 52.5px */
    }
  }

  > div:last-of-type {
    width: 90%;
    padding: 32px;
    max-height: 100%;

    border-radius: 14.305px;
    background: var(--Neutral-Gray-400, #f0f0f0);
    // background-color: red;

    > div:first-of-type {
      display: flex;
      flex-direction: column;
      overflow: auto;
      max-height: 85%;

      .item {
        display: flex;
        flex-direction: column;

        min-width: 70vw;

        border-bottom: solid 2px var(--Neutral-Gray-800, #5F5F5F);

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 4px;
          margin: 0 16px;

          > span {
            font-family: Roboto;
            font-size: 32.185px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 48.278px */
          }
        }

        > div:first-of-type > span:first-of-type {
          color: var(--Neutral-Gray-700, #a2a2a2);
        }
      }
    }

    > p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      margin-top: 16px;

      color: var(--Neutral-Gray-800, #5f5f5f);
      text-align: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 96.192px */
    }

    > button {
      display: flex;
      padding: 17.101px 25.651px;
      justify-content: center;
      align-items: center;
      gap: 8.55px;
      background: #19acfb;

      > span:first-of-type {
        color: var(--Neutral-White, #fff);
        text-align: center;

        /* Bold/5XL */
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
}
