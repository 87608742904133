#resumoLayoutPrinter {
  background-color: #ffffff;
  font-weight: 500;
  visibility: visible;
  overflow: visible;
  font-size: larger;
  border-top: 5px double #ddd;
  border-bottom: 5px double #000;
  position: relative;
}

.localName-ResumoLayoutPrinter {
  font-weight: 550;
}

.kioskName-ResumoLayoutPrinter {
  font-size: medium;
}

.container-ResumoLayoutPrinter {
  max-width: 400px;
  padding: 32px;
}

.local-ResumoLayoutPrinter {
}

.productPassWordOrderResume {
    border: 2px solid #000;
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #000;
    padding: 8px 0;
    margin: 8px 0;    
 }

.productPassWordOrderResume .textLabelSenha {
    font-size: 16px;
}

.productPassWordOrderResume .textSenha {
    font-size: xx-large;
    font-weight: bold;
}

.friendlyIdResume {
  margin-bottom: 10px;
}

.friendlyIdResume .textLabelPedido {
  font-size: 16px;
}

.friendlyIdResume .textPedido {
  font-size: large;
  font-weight: bold;
}

.friendlyId-ResumoLayoutPrinter {
  font-size: 38px;
  border: 2px solid #000;
  border-radius: 8px;
  margin-bottom: 16px;
}

.ordersItems-ResumoLayoutPrinter {
  text-align: left;
}

.ordersItem-ResumoLayoutPrinter {
  /* border-bottom: 3px dotted #000; */
}

.text-ordersItem-ResumoLayoutPrinter {
  display: flex;
  margin: 12px 0;
  justify-content: space-between;
  font-size: large;
}
.name-storeName-resumoLayoutPrinter:first-child {
  margin-top: 10px;
}
.name-storeName-resumoLayoutPrinter {
  font-weight: bold;
  font-size: 25px;
}

.name-ordersItem-ResumoLayoutPrinter {
}

.price-ordersItem-ResumoLayoutPrinter {
  margin-left: 16px;
}

.composition-ordersItem-ResumoLayoutPrinter {
  font-size: medium;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  text-align: left;
  border: 1px dashed #000;
}

.options-ordersItem-ResumoLayoutPrinter {
}

.optionConsumer-ordersItem-ResumoLayoutPrinter {
}

.descriptionOptionConsumer-ordersItem-ResumoLayoutPrinter {
  display: flex;
}

.optionsItemsOptionConsumer-ordersItem-ResumoLayoutPrinter {
  margin-left: 16px;
}

.optionItemOptionConsumer-ordersItem-ResumoLayoutPrinter {
  display: flex;
  justify-content: space-between;
}

.amount-ResumoLayoutPrinter {
  font-size: x-large;
  display: flex;
  justify-content: space-between;
}

.title-ResumoLayoutPrinter {
  font-weight: 550;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-size: 18px;
}

.observation-ResumoLayoutPrintern {
}
.date-ResumoLayoutPrinter {
  font-size: 16px;
}

.resume-layout-dotted-separator {
  margin: 16px 0;
  border-bottom: 3px dotted #000;
}
