#CpfRegisterPage {
    display: flex;
    // background-color: aqua;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
}
.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px;
    h2 {
        font-size: 38px;
        color: var(--secondary);
        text-transform: uppercase;
        justify-content: center;
        text-align: center;
    }
    .input {
        display: flex;
        flex: 1;
        width: 400px;
        text-align: center;
        justify-content: center;
        font-size: 50px;
        border-bottom: 2px solid var(--secondary);
    }
    .buttonContinuar {
        font-size: 32px;
        display: flex;
        padding: 16px;
        justify-content: center;
        background-color: var(--secondary);
        color: #fff;
        border-radius: 16px;
        margin-top: 16px;
        box-shadow: 2px 2px 8px -5px #0005;
    }
}
.dialogConfirm {
    padding: 16px;
    font-size: 32px;
    text-align: center;
    h2 {
        font-size: 38px;
        color: var(--primary);
        text-transform: uppercase;
    }
    .buttonContinuar {
        font-size: 32px;
        display: flex;
        padding: 16px;
        justify-content: center;
        background-color: var(--secondary);
        color: #fff;
        border-radius: 16px;
        margin-top: 16px;
        box-shadow: 2px 2px 8px -5px #0005;
    }
}
