.keyboardContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
    /* border: 1px solid #222 !important; */
    /* border-radius: 8px; */
    /* background-color: #ccc; */
    /* box-shadow: 5px 5px 20px -5px #0005; */
    z-index: 3;
    /* max-width: 800px; */
    word-break: break-all;
}

.keyboardLine {
    display: flex;
    flex: 1;
    word-break: break-all;
}

.containerKeyboardKey {
    padding: 4px;
    display: flex;
    flex: 1;
}

.keyboardValue{
    display: flex;
    font-size: xx-large;
    min-height: 80px;
    justify-content: center;
    align-items: center;
}
.keyboardLabel{
    display: flex;
    font-size: xx-large;
    min-height: 90px;
    justify-content: center;
    align-items: center;
}
.keyboardOnClose{
    position: absolute;
    top:0;
    right: 0;
}