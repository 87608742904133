.produto-detalhamento {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  overflow: scroll;
  align-items: center;
  color: #6b6b6b;
  background-color: var(--background);
  /* border-radius: 16px; */
}
.produto-detalhamento .image-detalhamento {
  position: relative;
  background-color: var(--primary);
  height: 64px;

  z-index: 1;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* visibility: hidden; */
}

.container-quantity-free-max {
  display: flex;
  padding: 0 16px;  
}

.container-quantity-free-max-text {
  background-color: #33D0B1;
  padding: 10px;
  border-radius: 22px;
  color: #333333;
}

.descricao-detalhamento-object{
  text-align: center ;
}

.close-Button{
  position: absolute!important;
  top: 8px!important;
  right: 8px!important;
  /* background-color: #1ca!important; */
  color: #fff!important;
}
.produto-detalhamento .conteudo-detalhamento {
  position: relative;
  /* width: 100vw; */
  min-height: 400px;
  z-index: 1;
  background-color: var(--background);
}

.observation {

  padding-bottom: 100px;
}

.produto-detalhamento .titulo-detalhamento {
  font-size: 36px;

  text-align: center;
}
.produto-detalhamento .descricao-detalhamento {
  font-size: 22px;
  opacity: 0.6;
}

.rodape-detalhamento {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  /* height: 70px; */
  left: 0;
  right: 0;
  /* border-top: 2px solid #ddd; */
  /* background-color: aqua; */
  background-color: var(--background);
  padding: 16px;
  animation: in-rodape 0.3s;
}
.input {
  border: 2px solid #BDBDBD;
  border-radius: 10px;
  text-align: start;
  padding: 16px;
  color: #5f5f5f;
  margin: 16px;
}

.container-quantidade-detalhamento {
  display: flex;
  margin-right: 32px;
}
.add-quantidade-detalhamento {
  /* background-color: #1ca !important; */
  background-color: #fff !important;
  border: 2px solid #1ca !important;
  color: #1ca !important;

  min-height: 60px !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.quantidade-detalhamento {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  border-top: 2px solid #0002;
  border-bottom: 2px solid #0002;
}
.remove-quantidade-detalhamento {
  background-color: #fff !important;
  border: 2px solid #f77 !important;
  color: #f77 !important;
  min-height: 60px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.observation-label {
  padding: 16px;
}

.botao-continuar-detalhamento {
  background-color: var(--primary) !important;
  /* border: 2px solid #f77 !important; */
  color: #fff !important;
  padding: 0 32px !important;
  font-size: 26px !important;
  height: 60px !important;
}

@keyframes in-rodape {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
