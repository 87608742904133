.botaoGrande {
    /* background-color: aqua !important; */
    padding: 16px 32px !important;
    justify-content: center;
    /* background-color: var(--primary) !important; */
    color: var(--on-primary) !important;
    border: solid 2px var(--on-primary) !important;
    font-size: 30px !important;
    border-radius: 8px !important;
    display: flex;
    flex: 1;
    text-transform: uppercase;
    box-shadow: 0px 0px 10px -5px;
    background-color: var(--primary)!important;
    /* background-color: rgba(255,23,68,1)!important; */
    /* background: linear-gradient(
    180deg,
    rgba(255,23,68,1) 0%,
    rgba(192, 13, 30, 1) 70%
  ); */
    margin: 4px;
}