.composicao-lista {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 32px;
  /* background-color: aqua; */
  padding-bottom: 36px;
  /* border: 1px solid #666; */
}
.transitionComposicao-container {
  position: relative;
}

.transitionComposicao-enter {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
}
.transitionComposicao-enter-active {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
  transition: opacity 200ms;
}
.transitionComposicao-exit {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
}
.transitionComposicao-exit-active {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 200ms;
}

.composicao-buttons-container {
  position: sticky;
  top: 210px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding:0 16px;
  background-color: var(--background);
  /* border-radius: 100px; */
  /* background-color: #fff; */
  /* border: 1px solid #666; */
  /* box-shadow: 2px 2px 2px #0002; */
  z-index: 1;
}

.composicao-lista-button-container {
  display: flex;
  /* flex: 1; */
  transition: width 0.5s;
  top: 10px;
  bottom: 10px;
  height: 60px;
}
.composicao-lista-button {
  border-radius: 60px !important;
  font-size: 18px !important;
  font-weight: 500px !important;
  padding: 0 24px !important;
  transition: width 0.5s;
}
.composicao-buttons-descricao {
  font-size: 22px;
}
