:root {
    --primary: #c00d1e;
    --secondary: #ff1744;
    --disabled: #ddd, 100%;
    /* --primary: #0051bb; */
    --secondary: #1ac;
    --on-primary: #fff;
    --background: #fff;
    --text: #6b6b6b;
    --titulo-categoria: #6b6b6b;
}
