.itemListaCategorias {
  position: relative;
  /* background-color: aqua; */
  text-align: center;
  display: flex;
  flex: 1;
  /* height: 200px; */
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border-radius: 4px; */
  /* overflow: hidden; */
  
  margin: 16px;
  /* box-shadow: 6px 6px 30px -20px var(--primary); */
  animation: in-anim 1s;
}
.itemListaCategorias .imagem {
  position: relative;
  /* border: 8px #fff solid; */
  width: 200px;
  height: 200px;
  background-color: #0003;
  border-radius: 100px;
  box-shadow: 6px 6px 30px -20px #666;

  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;
}
.itemListaCategorias .nome {
  font-size: 22px;
  color: #000;
  z-index: 1;
}

@keyframes in-anim {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
