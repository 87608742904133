.produto-lista-seccionado {
  position: relative;
  padding-bottom: 150px;
  scroll-behavior: smooth;

}
.titulo {
  font-size: 26px;
  text-align: left;
  padding: 8px 16px;
}
.container-produto {
}

.sessao {
}
