#cardpayment {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    background-color: #fff;
    color: #fff;
    color: #000;
}

#cardpayment .logo {
    position: absolute;
    width: 100vw;
    height: 75px;
    object-fit: contain;
    background-color: #fff;
    padding: 16px 0;
    object-position: center;
    top: 75px;
}

#cardpayment .gif {
    height: 150px;
    object-fit: contain;
    margin-bottom: 32px;
}

#cardpayment .instruction-label {
    /* color: #ED0029; */
    font-size: 40px;
}

#cardpayment .toolbar {
    background-color: var(--primary);
}

#cardpayment .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* margin-bottom: 150px; */
}

#cardpayment .nickname-container p {
    /* color: #ED0029; */
}

#cardpayment .nickname-container label {
    /* color: #ED0029; */
}

#cardpayment .nickname-container {
    font-size: 40px;
    width: 50%;
}

#cardpayment .nickname-container .nickname-input {
    border: none;
    border-bottom: 2px solid #BDBDBD;
    /* border-radius: 10px; */
    text-align: center;
    padding: 16px;
    color: #5f5f5f;
    margin: 16px;
    margin-top: 40px;
}

#cardpayment .nickname-container .nickname-value-input div {
    line-height: 30px;
}


/* #cardpayment .payment-button {
    
    display: flex;
    border-radius: 8px;
    font-size: xx-large;
    justify-content: center;
    text-transform: uppercase;
    padding: 16px;
    margin-top: 16px;
    color: #fff;
    min-width: 350px;
    margin: 4px;
} */

.container-button {
    display: flex;
    margin-bottom: 16px;
}

.container-button .button {
    /* font-size: xx-large; */
    display: flex;
    flex: 1;
    border-radius: 8px;
    justify-content: center;
    text-transform: uppercase;
    padding: 16px;
    margin-top: 16px;
    color: #fff;
    font-size: x-large;
    background-color: var(--primary);
    transition: opacity 0.5s;
}

.container-recharge {
    min-width: 400px;
}

.titulo-recharge {
    font-size: 28px;
    text-align: left;
    opacity: 0.8;
}

.instrucao-recharge {
    font-size: 28px;
    text-align: center;
    opacity: 0.8;
}

.value-recharge {
    flex: 1;
    font-size: 38px;
    text-align: center;
    /* background-color: aqua; */
}

.input-recharge {
    min-width: 400px;
    display: flex;
    height: 50px;
    flex-direction: row;
    border: 2px solid #ccc;
    color: #000;
    border-radius: 8px;
    margin: 16px;
    justify-content: center;
    align-items: center;
}

.back-recharge {
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
    /* background-color: aqua; */
}

.icon-recharge {
    /* background-color: aqua; */
    font-size: 50px !important;
    color: #f66 !important;
}

.erro-recharge {
    color: #f00;
    text-align: center;
    font-size: 18px;
    height: 26px;
}


.instruction-modal-label{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    outline: none;
    height: 200px;
    width: 600px;
}