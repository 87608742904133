#ticketprodutoPedidoPos {
    background-color: #ffffff;
    font-weight: 500;
    visibility: visible;
    overflow: visible;
    font-size: larger;
    border-top: 5px double #ddd;
    border-bottom: 5px double #444;
    position: relative;
}

.container-ticketPrinter {
    max-width: 400px;
    padding: 32px;
}

#ticketprodutoPedidoPos .localName {
    font-weight: 550;
}

#ticketprodutoPedidoPos .header {}

#ticketprodutoPedidoPos .qrCode {
    /* border: 2px solid #444; */
    padding: 16px;
    /* border-radius: 8px; */
}

#ticketprodutoPedidoPos .productPassWordOrder { 
   border: 2px solid #000;
   display: flex;
   flex: 1;
   flex-direction: column;
   color: #000;
   padding: 8px 0;
   margin: 8px 0;    
}

#ticketprodutoPedidoPos .productPassWordOrder .textLabelSenha {
    font-size: 16px;

}

#ticketprodutoPedidoPos .friendlyId{
    font-size: 38px;
    font-weight: bold;

}

#ticketprodutoPedidoPos .productPassWordOrder .textSenha {
    font-size: xx-large;
    font-weight: bold;
}

#ticketprodutoPedidoPos .friendlyId .textLabelPedido{
    font-size: 16px;
}

#ticketprodutoPedidoPos .friendlyId .textPedido{
    font-size: large;
    font-weight: bold;
}

#ticketprodutoPedidoPos .observation{}
#ticketprodutoPedidoPos .kioskName {}

#ticketprodutoPedidoPos .productCategory {
    font-weight: 550;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 18px;
}

#ticketprodutoPedidoPos .productName {
    font-size: x-large;
    padding: 8px 0;
}

#ticketprodutoPedidoPos .productPrice {
    font-size: xx-large;
}

#ticketprodutoPedidoPos .consumerOptions {
    font-size: medium;
    margin: 8px;
    padding: 8px;
    border-radius: 8px;
    text-align: left;
    border: 1px dashed #000;
}

#ticketprodutoPedidoPos .consumerOptions .descricao {
    font-size: medium;
    font-weight: 550;
}

#ticketprodutoPedidoPos .consumerOptions .option {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
}