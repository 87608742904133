.inicio {
    position: relative;
}

.inicio .botaocontainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 32px;
    bottom: 64px;
}

.inicio .botao {
    display: flex;
    flex: 1;
    min-width: 300px;
    max-width: 100vw;
    /* background-color: #555; */
    margin: 8px;
    /* bottom: 32px; */
}

.inicio .botao-inicio {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    /* bottom: 32px; */
    padding: 32px;
}
