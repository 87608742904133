#Pix {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px;
    border-radius: 24px;
    border: none;
    outline: none;
}

.pixBody {

}

.pixQrCodeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding: 32px 64px;
    >p {
        color: #5f5f5f;
        font-size: 28px;
    }
    >p:first-of-type {
        font-weight: 700;
    }
    >p:last-of-type {
        width: 550px;
        text-align: center;
    }
}

.qrCodeBase64 {}

.pixQrCode {
    >img {
        width: 300px;
        height: 300px;
    }
}

.countdownContainer{
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 30px;

    >div{
        display: flex;
        align-items: center;
        text-align: center;
    }

    span {
        color: #5F5F5F;
        font-weight: bold;
    }

    >span {
        margin: 0 0.5rem;
    }
}

.totalValue {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: #0009 solid 2px;

    >p {
        color: #5F5F5F;
        padding: 0 16px;
        font-size: 30px;
    }
}

.cancelBotaoGrande {
    margin-top: 16px;
    width: 100%;
    border-radius: 12px;
    border: 4px solid var(--Primary-Primary, #32008E);

    color: var(--Primary-Primary, #32008E);
    text-align: center;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 72px */
}