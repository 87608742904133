.container-store {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-image: url(./../../../../assets/images/bg-gray.png);
    background-size: cover;
    height: 100vh;
    /* flex: 1; */
}

.container-item-store {
    width: 100vw;
    /* display: grid; */
    /* margin: 16px; */
    /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
    /* grid-column-gap: 16px;
    grid-row-gap: 16px; */
    /* background-color: tomato; */
    justify-content: center;
    align-items: flex-start;
    position: relative;
    display: flex;
    flex: 0;
    margin-bottom: 80px;
    flex-direction: row;
    flex-wrap: wrap;
}

.text-item-store {
    text-align: center;
    font-size: 33px;
    color: #6b6b6b;
    animation: in-anim-titulo 1s;
}

@keyframes in-anim-titulo {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}