.Tabs {
  position: sticky;
  top: 80px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 2px 2px 10px -5px #0003;
  padding-bottom: 2px;
  z-index: 9999;
}
.Tab {
  
}
.customTabSelected {
  background-color: red !important;
  transition: all 0.5s ease-in-out;

}
.customTab {
  background-color: #32008E !important;
  transition: all 0.5s ease-in-out;
  color: #FFF !important;

}
.TabImage {
  top: -40px;
  object-fit: cover;
  border-radius: 60px;
  height: 100px;
  width: 100px;
  border: 5px solid #fff;
  box-shadow: 2px 2px 10px -5px #0003;
  animation: imageTab 500ms;
}

.TabBackground {
  /* background-color: #fff; */
}

@keyframes imageTab {
  0% {
    /* opacity: 0; */
    scale: 0%;
  }
  100% {
    /* opacity: 1; */
    scale: 100%;
  }
}
