.listaCatgorias{
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* background-color: aqua; */
  align-items: center;
  justify-content: center;
}
.listaCatgorias-titulo {
  text-align: center;
  font-size: 33px;
  color: #6b6b6b;
  animation: in-anim-titulo 1s;

}
.listaCatgorias-categorias {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: beige; */
}
.listaCatgorias-containerBotao{
  position: fixed;
  bottom: 150px;
}