.conteudo-alert-maioridade {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-TecladoCpf {
  min-width: 400px;
}

.instrucao-TecladoCpf {
  font-size: 28px;
  text-align: center;
  opacity: 0.8;
}
.value-TecladoCpf {
  flex: 1;
  font-size: 38px;
  text-align: center;
  /* background-color: aqua; */
}

.input-TecladoCpf {
  min-width: 400px;
  display: flex;
  height: 50px;
  flex-direction: row;
  border: 2px solid #ccc;
  color: #000;
  border-radius: 8px;
  margin: 16px;
  justify-content: center;
  align-items: center;
}

.back-TecladoCpf {
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  /* background-color: aqua; */
}
.icon-TecladoCpf {
  /* background-color: aqua; */
  font-size: 50px !important;
  color: #f66 !important;
}
.erro-TecladoCpf {
  color: #f00;
  text-align: center;
  font-size: 18px;
  height: 26px;
}
