#paymentCashless {
    padding: 16px;
}

#paymentCashless .item-paymentCashless {
    display: flex;
    flex: 1;
    /* background-color: aqua; */
    justify-content: space-between;
    margin-bottom: 16px;
}

#paymentCashless .botoes-paymentCashless {
    display: flex;
    flex: 1;
    /* background-color: aqua; */
    justify-content: space-between;
}

#paymentCashless .label-paymentCashless {}

#paymentCashless .value-paymentCashless {}

.drawer-paymentCashless {
    padding: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    /* background-color: aqua; */
}

.password-paymentCashless {
    font-size: 64px;
    text-align: center;
    height: 100px;
}

.description-paymentCashless {
    font-size: 64px;
    text-align: center;
}