#WeighingMachineModal {
    .container {
        /* width: 100vw; */
        display: flex;
        background-color: #fff;
        flex-direction: column;
        text-align: center;
    }
    .topImageWeighing {
        width: 100%;
        height: 200px;
        background-color: aqua;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    h2 {
        margin: 16px;
    }
    .content {
        display: flex;
        flex-direction: column;
        padding: 32px;
        h1 {
            margin: 16px;
            color: var(--secondary);
        }
        .weight {
            color: var(--secondary);
        }
        .price {
            color: #888;
        }
        .total {
            color: #2a0;
            font-size: 64px;
        }
        .productList {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            margin-bottom: 16px;
        }

        .imageWeighing {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            // background-color: aqua;
            img {
                max-width: 300px;
                object-fit: cover;
                object-position: center;
            }
        }
        .buttons {
            margin-top: 32px;
        }
    }
}
