.cardapio {
    position: relative;
}

.cardapio .container-botoes {
    position: fixed;
    bottom: 16px;
    right: 16px;
    left: 16px;
    background-image: url(../../assets/images/bg-gray.png);
}