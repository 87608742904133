#modalPrintNota {
    background-color: #c00;
    z-index: 50000;
}

#modalPrintNota .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 32px 0;
    color: #fff;
}

#modalPrintNota .text {
    margin-bottom: 16px;
    font-size: 46px;
}

#modalPrintNota .botoes {
    display: flex;
    flex: 1;
    width: 80%;
    padding: 0 36px;
}

#modalPrintNota .botao {
    display: flex;
    flex: 1;
    margin: 8px;
    font-size: 46px;
    color: #fff;
    padding: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    height: 62px;
}
