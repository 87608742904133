.App {
  text-align: center;
}

 /* CSS TRANSITION  */
.transition-router-enter {
  position: absolute;

  left: 0;
  right: 0;

  /* transform: translateX(-100vh); */
  opacity: 0;
}

.transition-router-enter.transition-router-enter-active {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  /* transform: translateX(0vh); */
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}

.transition-router-exit {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  /* transform: translateX(0vh); */
  opacity: 1;
}

.transition-router-exit.transition-router-exit-active {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
   /* transform: translateX(100vh);  */
  opacity: 0;
  transition: opacity 500ms, transform 500ms;
}
