.actionModalContainer {
	position: fixed;
	color: #fff;
	font-size: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	// width: 100vw;
	// height: 100vh;
	z-index: 10001;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.container {
	position: relative;
	background-color: #955CFF;
	animation: in-open 1s;
	border-radius: 16px;
	padding: 48PX;
}

.title {
	color: var(--Neutral-White, #FFF);
	text-align: center;
	font-family: Roboto;
	font-size: 80px;
	font-style: normal;
	font-weight: 900;
	line-height: 120%; /* 120px */
}

.close {
	position: absolute;
	top: 16px;
	right: 16px;
	color: #FFF;

	span {
		color: #FFF;
	}
}

.imageContainer {
	margin: 16px 0;
	display: flex;
	justify-content: center;
}

.image {
	mix-blend-mode: screen;
	width: 550px;
	height: 350px;
	object-fit: cover;
	align-self: center;
}

.text {
	margin: 16px 0;
	white-space: pre-line;
	max-width: 450px;
	margin: 24px 0;
	text-align: center;
}

.button {
	border-radius: 8px;
	background: var(--Neutral-White, #FFF);

	display: flex;
	padding: 16px 24px;
	justify-content: center;
	align-items: center;
	
	height: 94px !important;
	border: none !important;
	width: 100% !important;

	//text
	color: var(--Primary-Primary-900, #4D13B7);
	text-align: center;
	font-size: 45px;
	font-weight: 700;
	line-height: 150%; /* 82.5px */
}

.closeButton {
	margin-top: 16px;
	border-radius: 8px;
	background: var(--Neutral-White, #FFF);

	display: flex;
	height: 60px;
	padding: 16px 24px;
	justify-content: center;
	align-items: center;

	//text
	color: var(--Primary-Primary-900, #4D13B7);
	text-align: center;
	font-size: 45px;
	font-weight: 700;
	line-height: 150%; /* 82.5px */
}

@keyframes in-open {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}