.inputField {
    display: flex;
    flex-direction: column;
    color: #666;
    padding: 16px;
    background-color: #fff;
    border-radius: 4px;
    /* background-color: blue; */
}

.description-inputField {
    text-align: center;
    font-size: 32px;
}

.teclado-inputField {}

.value-inputField {
    height: 50px;
    border: #0001 solid 2px;
    /* box-shadow: #0003 2px 2px 10px -5px; */
    margin-bottom: 16px;
    border-radius: 4px;
    font-size: 32px;
    text-align: center;
}

.buttons-inputField {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    /* background-color: aqua; */
}

.button-inputField {
    border-radius: 8px !important;
}

.continuar-inputField {
    display: flex !important;
    flex: 1 !important;
    background-color: var(--primary) !important;
    color: #fff !important;
    margin-left: 8px !important;
    font-size: 24px !important;
}

.nao-informar-inputField {
    line-height: 22px !important;
    font-size: 18px !important;
    display: flex !important;
    flex: 0.5 !important;
    color: var(--primary) !important;
    border: var(--primary) 2px solid !important;
    transition: opacity 0.3s !important;
}

.disabled-continuar-inputField {
    opacity: 0.3;
    transition: opacity 0.3s !important;
}

.enable-continuar-inputField {
    opacity: 1;
    transition: opacity 0.3s !important;
}

.keyboar-container-inputField {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    left: 0;
    right: 0;
}