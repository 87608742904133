#offline-recharge {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 1;
    animation: in-anim 0.5s;
    justify-content: space-between;
    align-items: center;
}

#offline-recharge .offline-recharge-header {
    padding-top: 36px;
    /* background-color: red; */
}

.offline-recharge-header-idle-counter {
    position: absolute;
    right: 16px;
    top: 16px;
}

.offline-recharce-success-message-h3 {
    width: 500px;
}

.offline-recharce-success-message-div1 {
    border: solid 1px white;
    margin: 32px 0;
    padding: 32px 0;
}

.offline-recharce-success-message-div1 p {
    margin: 0;
}

.offline-recharge-steps {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}

.offline-recharge-steps >div {
    display: flex;
    min-width: 120px;
    height: 80px;
    margin: 0 8px;
    border: 2px solid #BDBDBD;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: #BDBDBD;
    font-weight: bold;
}

.offline-recharge-nfc-image {
    width: 700px;
}

.offline-recharge-steps-selected {
    /* padding: 0 60px; */
    color: white !important;
    border-color: #955CFF !important;
    background-color: #955CFF;
}

.offline-recharge-totem {
    width: 900px;
    height: 500px;
    object-fit: cover;
}

.offline-recharge-totem-message-read >p {
    color: #fff;
    font-size: 70px;
    margin: 0;
    margin-top: 16px;
}

.offline-recharge-totem-message-read-b {
    margin-top: 64px;
}

.offline-recharge-totem-message-read-b >p {
    color: #fff;
    font-size: 70px;
    margin: 0;
    margin-top: 16px;
}

@keyframes fa-blink {
    0% { opacity: 0.2; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
}
.fa-blink {
  -webkit-animation: fa-blink 1s linear infinite;
  -moz-animation: fa-blink 1s linear infinite;
  -ms-animation: fa-blink 1s linear infinite;
  -o-animation: fa-blink 1s linear infinite;
  animation: fa-blink 1s linear infinite;
}



.offline-recharge-totem-message >p {
    color: #32008E;
    font-size: 70px;
    margin: 0;
    margin-top: 16px;   
}

.offline-recharge-totem-message >p:first-of-type {
    font-weight: bold;
}

.offline-recharge-totem-message >p:first-of-type {
}

.offline-recharge-empty {
    height: 400px
}

.offline-recharge-balance {
    display: flex;
    flex-direction: column;
    padding: 16px;
    /* border-radius: 16px;
    border: 4px solid #955CFF;
    background: #F3EDFF; */
}

.offline-recharge-cpf {
    color: #BDBDBD;
    font-size: 40px;
}

.offline-recharge-totem-message-footer >p {
    color: #333;
    font-size: 50px;
    margin: 0;
}

.offline-recharge-balance >p:first-of-type{
    margin: 0;
    font-size: 30px;
    color: #32008E;
}

.offline-recharge-balance >p:last-of-type{
    margin: 0;
    font-size: 80px;
    font-weight: bold;
    color: #32008E;
}

.offline-recharge-value {
    width: 100%;
    margin: 0 48px;
}

.offline-recharge-value >p:first-of-type{
    color: #5F5F5F;
    font-size: 48px;
    margin: 16px 0;
}

.offline-recharge-value-container {
    min-width: 400px;
    display: flex;
    height: 100px;
    flex-direction: row;
    border: 2px solid #ccc;
    color: #000;
    border-radius: 8px;
    margin: 24px;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
}

.offline-recharge-input-value {
    flex: 1;
    font-size: 50px;
    text-align: center;
}

.offline-recharge-back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 6px;
}

.offline-recharge-icon-back {
    font-size: 70px !important;
    color: #6C2EE0 !important;
}

.offline-recharge-aproved {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5F5F5F;
}

.offline-recharge-aproved >p {
    font-size: 50px;
    margin: 16px;
}

.offline-recharge-aproved >span {
    font-size: 50px !important;
}

.offline-recharge-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F3EDFF;
    border-radius: 32px 32px 0px 0px;
}

.offline-recharge-footer >p {
    color: #5F5F5F;
    margin: 16px 0;
    font-size: 40px;
}

.offline-recharce-success-message >p {
    margin: 16px;
    font-size: 30px;
}

.offline-recharce-success-message >h3 {
    margin: 32px;
    font-size: 60px;
}

#fullscreen-toast-papelDeParedePlayer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    filter: opacity(30%);
}

.offline-recharge-cancel-toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.offline-recharge-cancel-toast-container {
    background-color: white;
    border: none;
    outline: none;
    border-radius: 16px;
    padding: 32px;
}

.offline-recharge-cancel-toast-container >div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 26px 0;
}

.offline-recharge-cancel-toast-text  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.offline-recharge-cancel-toast-text  >p {
    margin: 0;
    color: #5F5F5F;
}

.offline-recharge-cancel-toast-icon {
    color: yellow;
    font-size: 100px !important;
}

.offline-recharge-cancel-toast-button-container {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes in-anim {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}