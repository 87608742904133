#container-orderlog {
  font-size: medium;
}

.message-container-orderlog {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    font-size: 32px;
    color: #fff;
}