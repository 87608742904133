#Cashless {
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 36px;
	
	.cashlessModal {
		background-color: white;
		width: 80%;
		border-radius: 8px;
		outline: none;
	}

	.container {
		position: relative;
	}

	.closeIcon {
		position: absolute;
		top: 0px;
		right: 0px;
		color: rgb(255, 255, 255);
	}

	.topLabel {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		justify-content: center;
		text-align: center;
		padding: 16px;
		background-color: var(--primary);
		color: #fff;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 16px;

		> span {
			margin-bottom: 8px;
		}
	}

	.paymentCashless {
		display: flex;
		margin: 16px;
		align-items: center;
    justify-content: space-between;
	}

	.paymentCashlessValue {
		align-self: flex-end;
	}

	.buttonsContainer {
		display: flex;
		margin: 16px;
	}

	.cancel {
		margin-right: 6px;
		padding: 12px;
		flex: 1 1;
		color: var(--primary);
		border-radius: 4px;
		border: 2px solid var(--primary);
		background-color: transparent;
		font-size: xx-large;
		text-align: center;
	}

	.confirm {
		margin-right: 6px;
		padding: 12px;
		flex: 1 1;
		background-color: var(--primary);
		color: var(--on-primary);
		font-size: xx-large;
		flex: 1 1;
		border-radius: 4px;
		text-align: center;
	}
}
