.carrinho {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 20px -10px;
  /* border-top-left-radius: 4px;
  border-top-right-radius: 4px; */
  background-color: #fff;
  overflow: hidden;
  height: 90vh;
  z-index: 1;
}

.carrinho-fullscreen {
  height: 100vh;
}

.carrinho-aberto {
  /* height: 100vh; */
  /* transition: height 0.5s ease-in-out; */
  transform: translateY(0vh);
  transition: transform 0.5s ease-in-out;
}
.carrinho-aberto-fullscreen {
  /* height: 100vh; */
  /* transition: height 0.5s ease-in-out; */
  transform: translateY(0vh);
  transition: transform 0.5s ease-in-out;
}
.carrinho-oculto {
  transform: translateY(100vh);
  transition: transform 0.5s ease-in-out;
  /* height: 0; */
  /* transition: height 0.5s ease-in-out; */
}

.carrinho-fechado {
  transform: translateY(calc(90vh - 80px));
  transition: transform 0.5s ease-in-out;
  /* height: 80px; */
  /* transition: height 0.5s ease-in-out; */
}

.carrinho .barra {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  flex-direction: row;
  background: rgba(255, 23, 68, 1);
  /* border-top-left-radius: 16px;
  border-top-right-radius: 16px; */
  /* background: linear-gradient(
    180deg,
    rgba(255, 23, 68, 1) 0%,
    rgba(192, 13, 30, 1) 100%
  ); */
  /* background-color: #1ac; */
  height: 80px;
  font-size: 32px;
  color: var(--on-primary);
}
.carrinho .conteudo {
  position: absolute;
  top: 80px;
  overflow: scroll;
  left: 0;
  right: 0;
  bottom: 205px;
  padding-bottom: 200px;
  /* border: 1px solid green; */
}
.carrinho .rodape {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: var(--background);
}

.carrinho .total {
  display: flex;
  justify-content: space-between;
  font-size: 44px;
  border-top: 2px solid #555;
  /* color: var(--primary); */
  padding: 32px;
}
.pedidoItensContainer {
  padding: 16px;
  /* margin-bottom: 150px; */
}
.pedidoItensContainer .more-itens {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  font-size: 26px;
  font-weight: bold;
  color: #1a6;
}
.pedidoItensContainer .more-itens >span {
  margin-right: 8px;
}
.carrinho-item-container {
  margin-bottom: 16px;

}

.order-item-enter {
  opacity: 0;
  height: 0px;
}

.order-item-enter-active {
  opacity: 1;
  height: 130px;

  transition: opacity 300ms ease-in, height 200ms 200ms ease-in;
}
.order-item-exit {
  opacity: 1;
  height: 130px;
}
.order-item-exit-active {
  opacity: 0;
  height: 0px;

  transition: opacity 200ms ease-in, height 200ms 200ms ease-in;
}

.botao-limpar-carrinho {
  display: flex;
  padding: 8px 32px;
  margin-bottom: 8px;
  /* max-width: 300px; */
  border-radius: 8px;
  font-size: 28px !important;
  justify-content: center;
  align-self: center;
  align-items: center;
  color: #fff !important;
  background-color: #f55 !important;
}

.subtotal {
  border-top: 1px solid #0005;
  padding: 0 32px;
  font-size: xx-large;
  display: flex;
  justify-content: space-between;
}
.serviceFeeTotal {
  padding: 0 32px;
  font-size: xx-large;
  display: flex;
  justify-content: space-between;
}
