.toastProduto {
  display: flex;
  flex: 1;
  position: fixed;
  justify-content: center;
  right: 0;
  left: 0;
  bottom: 100px;
  z-index: 3;
}
.container-toastProduto {
  display: flex;
  flex-direction: row;
  border: 4px solid var(--primary);
  box-shadow: 5px 5px 15px 0px #0009;
  height: 100px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
}
.image-container-toastProduto {
}
.image-container-toastProduto {
  width: 100px;
  height: 100px;
  margin-right: 16px;
}
.info-toastProduto {
  font-size: 26px;
}
.nome-toastProduto {
  color: var(--primary);
}
.text-toastProduto {
  color: #1ac;
}

.anim-toastProduto-enter {
  opacity: 0;
  /* transform: translateY(210px); */
}
.anim-toastProduto-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.anim-toastProduto-exit {
  opacity: 1;
  transform: translateY(0);
}
.anim-toastProduto-exit-active {
  opacity: 0;
  transform: translateY(300px);
  transition: transform 500ms, opacity 500ms;
}
