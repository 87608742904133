.StepComposicao {
  position: relative;
  display: flex;
  flex: 1;
  padding: 8px 32px;
  font-size: 14px;
  align-items: flex-start;
  position: sticky;
  top: 100px;
  right: 0;
  left: 0;
  /* color: #fff; */
  max-width: 90vw;
  overflow: scroll;
  background-color: var(--background);
  /* background-color: #666; */
  z-index: 1;
}
.StepItemComposicao {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-width: 100px;
}

.StepComposicaoItemContainer {
  display: flex;
  /* flex:1 */
}

.iconStepItemComposicao {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: var(--primary);
}
.textStepItemComposicao {
  min-width: 100px;
  max-width: 200px;
}

.StepDivisorComposicao {
  display: flex;
  flex: 1;
  align-items: center;
}

.StepDivisorComposicao .line {
  display: flex;
  flex: 1;
  background-color: #aaa;
  margin: 24px;
  height: 2px;
}

.containerItemStepComposicao {
  display: flex;
  flex: 1;

}
