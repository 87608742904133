#InputText {
    .inputContainer {
        display: flex;
        flex-wrap: wrap;
        .placeHolder {
            width: 80vw;
            opacity: 0.5;
        }
    }
    .keyboard {
        z-index: 2;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        box-shadow: 10px 10px 10px 10px;
    }
}
