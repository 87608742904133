.content-orderItemPreview {
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.nome-orderItemPreview {
}
.quantidade-orderItemPreview {
    text-align: center;
}
.total-orderItemPreview {
}
.actions-orderItemPreview {
}
