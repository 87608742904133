.container-barcode {
    /* background: url("../../assets/videos/bg-barcode.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.message-bacode {
    font-size: 32px;
}

.alert-toast-enter {
    opacity: 0;
}

.alert-toast-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.alert-toast-exit {
    opacity: 1;
}

.alert-toast-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.alert-barcode {
    position: fixed;
    bottom: 32px;
    left: 32px;
    right: 32px;
    border-radius: 16px;
    background-color: orange;
    color: #fff;
    padding: 16px;
    font-size: 32px;
    z-index: 3;
}

.botao-comprar-manualmente {
    position: fixed;
    bottom: 96px;
    left: 32px;
    right: 32px;
    margin: auto;
}
