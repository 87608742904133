.container-admin {
  background-color: var(--primary);

  height: 100vh;
  justify-content: center;
}
.top-admin {
}
.toolbar-admin {
  height: 68px;
  width: 100vw;
  background-color: var(--primary);
}
.tab-admin {
}
.content-admin {
  background-color: #fff;
  padding: 32px;
}
.footer-admin {
  /* background-color: #fff; */
  align-self: center;
}
.buttonReload{
  padding: 8px;
  color: var(--primary);
  color: var(--primary);
  border: var(--primary) 2px solid;
  background-color: #fff;
  max-width: 200px; margin: auto;
  border-radius: 16px;
}