.papelDeParedePalyer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #222;
  /* z-index: 2; */
}

#player-papelDeParedePlayer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: aqua; */
  object-fit: cover;
}

.children-papelDeParedePlayer {
  position: fixed;
  width: 100%;
  height: 100%;
  /* background-color: aquamarine; */
  /* z-index: 1; */
  box-shadow: inset 0px 85px 25px -10px rgba(0,0, 0, 0.2);

}

.top-papelDeParedePlayer {
  position: fixed;
  top: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 50px;
  z-index: 3;
}
.logoMeep-papelDeParedePlayer {
  position: fixed;
  top: 0px;
  margin: auto;
  /* background-color: #456; */
  height: 80px;
  width: 200px;
  z-index: 3;
  background-image: url("../../../../assets/images/logo-meep-white.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.weighing-gif-papelDeParedePlayer {
  /* width: 500px;
  height: 400px; */
  object-fit: cover;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
