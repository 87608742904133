.itemListaStore {
    position: relative;
    /* background-color: aqua; */
    text-align: center;
    /* display: flex; */
    flex: 1;
    /* height: 200px; */
    align-items: center;
    margin: 16px;
    /* border-radius: 4px; */
    /* overflow: hidden; */
    /* box-shadow: 6px 6px 30px -20px var(--primary); */
    /* animation: in-anim 1s; */
}

.itemListaStore .imagem {
    position: relative;
    display: flex;
    border-radius: 16px;
    /* border: 8px #fff solid; */
    min-width: 300px;
    height: 250px;
    /* border-radius: 100px; */
    /* background-color: #0003; */
    /* box-shadow: 6px 6px 30px -20px #666; */
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply;
    max-width: 50vw;
    margin: auto;
}

.itemListaStore .closed {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 16px;
    top: 8px;
    right: 8px;
    background-color: #DE9C00;
}

.itemListaStore .nome {
    font-size: 22px;
    color: #000;
    z-index: 1;
}

@keyframes in-anim {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}