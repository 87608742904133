#extract-view {
    position: absolute;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* background-color: rgb(255, 254, 199); */
    background-color: #eee;
    /* overflow: scroll; */
    min-width: 500px;
    max-width: 600px;
    /* max-height: 80vh; */
    max-height: calc(100% - 150px);
    padding: 16px;
    border-top: 8px dotted #fff;
    border-bottom: 8px dotted #fff;
    font-size: large;
    color: #222;
    opacity: 1;
    animation: in-anim 0.5s;
}

#extract-view .name {}

#extract-view .moviment {
    overflow: auto;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

#extract-view .movimentItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid black;
    padding: 8px;
    line-height: 30px;
}

#extract-view .movimentItem .top {
    display: flex;
    justify-content: space-between;
}

#extract-view .date {
    font-size: medium;
}

#extract-view .description {
    text-align: left;
}

#extract-view .credit {
    margin-left: 16px;
}

#extract-view .debit {
    margin-left: 16px;
}

#extract-view .balance {
    font-size: xx-large;
    display: flex;
    justify-content: space-between;
}

#extract-view .footer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

#extract-view .payment-button {
    /* font-size: xx-large; */
    display: flex;
    flex: 1;
    border-radius: 8px;
    justify-content: center;
    text-transform: uppercase;
    padding: 16px;
    margin-top: 16px;
    color: #fff;
    transition: opacity 0.5s;
}

#extract-view .payment-button:hover {
    opacity: 0.7;
}

#extract-view .remover-taxa-button {
    display: flex;
    flex: 1;
    border-radius: 8px;
    justify-content: center;
    text-transform: uppercase;
    padding: 8px;
    margin-top: 16px;
    font-size: small;
    color: #f52;
    border: #f52 solid 1px;
    transition: background-color 0.5s, color 0.5s;
}

#extract-view .remover-taxa-button:hover {
    opacity: 0.7;
    background-color: tomato;
    color: #fff;
}

@keyframes in-anim {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}