#CpfRegisterLayoutPrinter {
    .container {
        font-size: large;
        max-width: 600px;
        padding: 32px;
        h1{
            font-size: 50px;
        }
        .user {
            padding: 16px;
            border: 1px solid #000;
            border-radius: 16px;
            .item {
                font-weight: bold;
                border-bottom: 1px dashed #000;
                span{
                    font-size: xx-large;
                }
            }
        }
        .price {
        }
        .qrCode {
        }
    }
}
